import React, { useState } from 'react';
import { Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { deleteRoleFromUser, IndividualUserRole } from '../../../features/users/UsersSlice';
import { useAppSelector } from '../../../hooks';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import AddRoleDialog from './AddRightDialog/AddRoleDialog';
import { hasRight } from '../../../helpers/hasRight';

const RightsModule = (): JSX.Element => {
    const user = useAppSelector((state) => state.users.user);
    const dispatch = useDispatch();
    const [isAddRightDialogOpen, setIsAddRightDialogOpen] = useState(false);

    const onDeleteRightClick = (roleId: number): void => {
        if (user) {
            dispatch(deleteRoleFromUser(user?.id, roleId))
        }
    }

    const onAddRightDialogOpenClick = (): void => {
        setIsAddRightDialogOpen(true);
    }

    const onAddRightDialogClose = (): void => {
        setIsAddRightDialogOpen(false);
    }

    return (
        <div>
            <AddRoleDialog
                open={isAddRightDialogOpen}
                onClose={onAddRightDialogClose}
            />
            <Grid container>
                <Grid item xs={6}>
                    <h2>Roles</h2>
                </Grid>
                <Grid item xs={6}>
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Role</TableCell>
                            {hasRight('add_role_to_user') &&
                                <TableCell align="right">
                                    <Button onClick={onAddRightDialogOpenClick}>Add role</Button>
                                </TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {user?.roles.map((role: IndividualUserRole) => (
                            <TableRow
                                key={role.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {role.name}
                                </TableCell>
                                {hasRight('remove_role_from_user') &&
                                    <TableCell align="right">
                                        <IconButton onClick={() => { onDeleteRightClick(role.id) }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default RightsModule