import React, { useState } from 'react';
import { Button, Card, CardActions, CardContent, Grid, IconButton, Typography } from '@mui/material';
import { deleteTimeslot, subscribeUserToTimeslot, Timeslot, TimeslotUser, unsubscribeUserToTimeslot } from '../../../features/timeslots/timeslotSlice';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppSelector } from '../../../hooks';
import { useDispatch } from 'react-redux';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import { hasRight } from '../../../helpers/hasRight';
import SubscribeUserToTimeslotDialog from './SubscribeUserToTimeslotDialog/SubscribeUserToTimeslotDialog';

interface TimeslotCardInterface {
    timeslot: Timeslot
}

const TimeslotCard = ({ timeslot }: TimeslotCardInterface): JSX.Element => {
    const [openDeleteTimeslotDialog, setOpenDeleteTimeslotDialog] = useState(false);
    const [openSubscribeOtherUserDialog, setOpenSubscribeOtherUserDialog] = useState(false);
    const [userToUnsubscribeFromTimeslot, setUserToUnsubscribeFromTimeslot] = useState<number | null>(null);
    const authenticatedUserId = useAppSelector((state) => state.user.userInformation?.id);
    const dispatch = useDispatch();

    const onSubscribeClick = (): void => {
        if (authenticatedUserId) {
            dispatch(subscribeUserToTimeslot(authenticatedUserId, timeslot.id));
        }
    };

    const onUnsubscribeClick = (): void => {
        if (authenticatedUserId) {
            dispatch(unsubscribeUserToTimeslot(authenticatedUserId, timeslot.id));
        }
    };

    const onDeleteTimeslot = (): void => {
        dispatch(deleteTimeslot(timeslot.id));
        setOpenDeleteTimeslotDialog(false);
    }

    const onDeleteUserFromTimeslot = (): void => {
        if (userToUnsubscribeFromTimeslot) {
            dispatch(unsubscribeUserToTimeslot(userToUnsubscribeFromTimeslot, timeslot.id));
            setUserToUnsubscribeFromTimeslot(null);
        }
    }

    return (
        <Grid item xs={12} md={6} lg={4} xl={3}>
            <ConfirmDialog
                open={openDeleteTimeslotDialog}
                body="Are you sure you want to delete this timeslot?"
                handleClose={() => { setOpenDeleteTimeslotDialog(false) }}
                handleSucces={onDeleteTimeslot}
            />
            <ConfirmDialog
                open={!!userToUnsubscribeFromTimeslot}
                body="Are you sure you want to unsubscribe this user from the timeslot?"
                handleClose={() => { setUserToUnsubscribeFromTimeslot(null) }}
                handleSucces={onDeleteUserFromTimeslot}
            />
            <SubscribeUserToTimeslotDialog
                open={openSubscribeOtherUserDialog}
                onClose={() => { setOpenSubscribeOtherUserDialog(false) }}
                timeslotId={timeslot.id}
            />
            <Card>
                <CardContent>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="h5" component="div">
                                {timeslot.name}
                            </Typography>
                        </Grid>
                        {hasRight('timeslot_delete') &&
                            <Grid item xs={4}>
                                <IconButton onClick={() => setOpenDeleteTimeslotDialog(true)} sx={{ float: 'right' }}>
                                    <DeleteIcon color="error" />
                                </IconButton>
                            </Grid>
                        }
                    </Grid>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        {timeslot.start_time} - {timeslot.end_time} ({timeslot.users.length}/{timeslot.capacity})
                    </Typography>
                    {timeslot.users.map((user: TimeslotUser) => {
                        return (
                            <div>
                                <Grid key={user.id} container>
                                    <Grid item xs={8}>
                                        <Typography display="flex" flexDirection="column" justifyContent="center" height="100%" >
                                            {user.name}
                                        </Typography>
                                    </Grid>
                                    {hasRight('timeslot_unsubscribe_other_user') &&
                                        <Grid item xs={4}>
                                            <IconButton onClick={() => setUserToUnsubscribeFromTimeslot(user.id)} sx={{ float: 'right' }}>
                                                <DeleteIcon color="error" />
                                            </IconButton>
                                        </Grid>
                                    }
                                </Grid>
                                <hr />
                            </div>
                        )
                    })}
                </CardContent>
                <CardActions>
                    {timeslot.users.some((user: TimeslotUser) => {
                        return user.id === authenticatedUserId
                    }) ?
                        hasRight('timeslot_unsubscribe') && <Button size="small" onClick={onUnsubscribeClick}>Unsubscribe</Button>
                        :
                        timeslot.users.length < timeslot.capacity && hasRight('timeslot_subscribe') && <Button size="small" onClick={onSubscribeClick}>Subscribe</Button>
                    }
                    {hasRight('timeslot_subscribe_other_user') && timeslot.users.length < timeslot.capacity &&
                        <Button size="small" onClick={() => setOpenSubscribeOtherUserDialog(true)}>Subscribe other user</Button>
                    }
                </CardActions>
            </Card>
        </Grid>
    );
};

export default TimeslotCard;