import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { createRole, setCreatedRoleId, setCreateRoleError } from '../../../features/roles/RolesSlice';
import { useAppSelector } from '../../../hooks';
import { useNavigate } from 'react-router';
import { Alert } from '@mui/material';

interface AddRightDialogInterface {
    open: boolean,
    onClose: () => void,
}

const CreateRoleDialog = ({ open, onClose }: AddRightDialogInterface): JSX.Element => {
    const dispatch = useDispatch();
    const [error, setError] = useState<string | null>(null);
    const createdRoleId = useAppSelector((state) => state.roles.createdRoleId)
    const createdRoleError = useAppSelector((state) => state.roles.createRoleError)
    const navigate = useNavigate();
    const [roleName, setRoleName] = useState<string | null>(null);

    const onCreateRole = (() => {
        if (roleName) {
            dispatch(createRole(roleName))
        } else {
            setError('please enter a role name.')
        }
    });

    useEffect(() => {
        if (createdRoleId) {
            dispatch(setCreatedRoleId(null));
            navigate('/roles/' + createdRoleId)
        }
    }, [createdRoleId, dispatch, navigate])

    useEffect(() => {
        if (createdRoleError) {
            dispatch(setCreateRoleError(null));
            setError(createdRoleError);
        }
    }, [createdRoleError, dispatch, navigate])


    const onRoleNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setRoleName(event.target.value);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Create Role</DialogTitle>
            <DialogContent sx={{ width: '500px' }}>
                <TextField
                    sx={{ marginTop: '10px' }}
                    label="Role name"
                    value={roleName || ''}
                    onChange={onRoleNameChange}
                    fullWidth
                />
                {error &&
                    <Alert sx={{ marginTop: '10px' }} severity="error">{error}</Alert>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onCreateRole}>Create Role</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateRoleDialog;