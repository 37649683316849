import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, TextField } from '@mui/material';
import { hasRight } from '../../helpers/hasRight';
import { useAppSelector } from '../../hooks';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { getDokExport, getDokResets, setDokExport } from '../../features/exports/ExportsSlice';
import { DateTime } from 'luxon';
import { DateTimePicker } from '@mui/lab';
import axios from 'axios';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';


interface ExportDokParams {
    created_before: DateTime,
    created_after: DateTime
}

const ExportDokPage = (): JSX.Element => {
    const authenticated = useAppSelector((state) => state.user.authenticated);
    const dokExport = useAppSelector((state) => state.exports.dokExport);
    const dokResets = useAppSelector((state) => state.exports.dokResets);
    const navigate = useNavigate();
    const [exportDokParams, setExportDokParams] = useState<ExportDokParams>({
        created_before: DateTime.now(),
        created_after: DateTime.now()
    });
    const dispatch = useDispatch();
    const [showResetDokDialog, setShowResetDokDialog] = useState(false);

    useEffect(() => {
        if (dokExport) {
            var element = document.createElement('a');
            element.setAttribute('href','data:text/csv;charset=utf-8, ' + encodeURIComponent(dokExport));
            element.setAttribute('download', 'dokexport.csv');
            document.body.appendChild(element);
            element.click();
            dispatch(setDokExport(null))
        }
    }, [dispatch, dokExport])

    useEffect(() => {
        let createdAfter = DateTime.fromJSDate(new Date('2020-01-01'));
        let createdBefore = DateTime.now();
        if (dokResets.length > 0) {
            createdBefore = DateTime.fromJSDate(dokResets[0])
        }
        if (dokResets.length > 1) {
            createdAfter = DateTime.fromJSDate(dokResets[1]);
        }

        setExportDokParams({created_before: createdBefore, created_after: createdAfter});
    }, [dokResets])

    useEffect(() => {
        dispatch(getDokResets());
    }, [dispatch])

    if (!authenticated) {
        return <></>;
    } else if (!hasRight('dok_export')) {
        navigate('/');
        return <></>;
    }

    const onCreatedAfterChange = (newTime: DateTime|null) => {
        if (newTime) {
            setExportDokParams({
                ...exportDokParams,
                created_after: newTime
            })
        }
    }

    const onCreatedBeforeChange = (newTime: DateTime|null) => {
        if (newTime) {
            setExportDokParams({
                ...exportDokParams,
                created_before: newTime
            })
        }
    }
    const onSearch = (): void => {
        dispatch(getDokExport(exportDokParams.created_after, exportDokParams.created_before, false));
    }

    const onSearchTcs = (): void => {
        dispatch(getDokExport(exportDokParams.created_after, exportDokParams.created_before, true));
    }

    const onResetDok = (): void => {
        axios.post('/api/dok/resets')
            .then(() => {
                document.location.reload();
            })
    };

    const onResetDokDialogClose = (): void => {
        setShowResetDokDialog(false);
    }

    const onResetDokButtonClick = (): void => {
        setShowResetDokDialog(true);
    }

    return (
        <div>
            <ConfirmDialog 
                open={showResetDokDialog}
                title="Reset dok"
                body="Are you sure you want to reset the dok"
                handleSucces={onResetDok}
                handleClose={onResetDokDialogClose}
            />
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} xl={4}>
                    <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="Created after"
                        value={exportDokParams.created_after}
                        onChange={onCreatedAfterChange}
                    /> 
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                    <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="Created before"
                        value={exportDokParams.created_before}
                        onChange={onCreatedBeforeChange}
                    />  
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {hasRight('dok_reset') && 
                    <Button
                        variant="contained"
                        onClick={onResetDokButtonClick}
                        sx={{ mt: 3, ml: 1 }}
                    >
                        Reset Dok
                    </Button>
                }
                <Button
                    variant="contained"
                    onClick={onSearch}
                    sx={{ mt: 3, ml: 1 }}
                >
                    Export
                </Button>
                <Button
                    variant="contained"
                    onClick={onSearchTcs}
                    sx={{ mt: 3, ml: 1 }}
                >
                    Export TCS
                </Button>
            </Box>
        </div>
    );
};

export default ExportDokPage;