import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import {Alert, Checkbox, FormControlLabel} from '@mui/material';
import { useAppSelector } from '../../../../hooks';
import {
    editUserInformation,
    IndividualUser,
    setEditUserError,
    setEditUserState
} from '../../../../features/users/UsersSlice';

interface createProductDialogInterface {
    open: boolean,
    onClose: () => void,
}

interface EditProductFormData {
    id: number | null,
    shortName: string | null,
    dokLimit: string | null,
    allowTcs: boolean,
    showUserInDok: boolean,
}

const EditUserInformationDialog = ({ open, onClose }: createProductDialogInterface): JSX.Element => {
    const dispatch = useDispatch();
    const [error, setError] = useState<string | null>(null);
    const individualUser = useAppSelector((state) => state.users.user);
    const editUserRequestError = useAppSelector((state) => state.users.editUserError);
    const editUserState = useAppSelector((state) => state.users.editUserState);
    const [editUserInformationFormData, setEditUserInformationFormData] = useState<EditProductFormData>({
        id: null,
        shortName: null,
        dokLimit: null,
        allowTcs: true,
        showUserInDok: true,
    });

    useEffect(() => {
        if (editUserState === 'success') {
            dispatch(setEditUserState('idle'));
            onClose();
        }
    }, [dispatch, editUserState, onClose])

    useEffect(() => {
        if (editUserRequestError) {
            dispatch(setEditUserError(null));
            setError(editUserRequestError);
        }
    }, [dispatch, editUserRequestError])

    useEffect(() => {
        if (individualUser) {
            setEditUserInformationFormData({
                id: individualUser.id,
                shortName: individualUser.short_name,
                dokLimit: ((individualUser.dok_limit || 0) / 100).toString(),
                showUserInDok: individualUser.show_user_in_dok,
                allowTcs: individualUser.allow_tcs
            });
        }
    }, [individualUser])

    const onEditProduct = (() => {
        if (
            editUserInformationFormData 
            && editUserInformationFormData.id 
            && editUserInformationFormData.shortName
            && editUserInformationFormData.shortName.length > 2
            && editUserInformationFormData.dokLimit
            && !isNaN(parseFloat(editUserInformationFormData.dokLimit))
            && parseFloat(editUserInformationFormData.dokLimit) >= 0
        ) {
            setError(null);
            dispatch(editUserInformation(
                editUserInformationFormData.id,
                editUserInformationFormData.shortName,
                parseFloat(editUserInformationFormData.dokLimit) * 100,
                editUserInformationFormData.showUserInDok,
                editUserInformationFormData.allowTcs
            ));
        } else {
            setError('Please enter a valid DOK identifier and DOK limit.')
        }
    });

    const onShortNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setEditUserInformationFormData({
            ...editUserInformationFormData,
            shortName: event.target.value
        });
    };

    const onDokLimitChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setEditUserInformationFormData({
            ...editUserInformationFormData,
            dokLimit: event.target.value
        });
    };

    const allowTcsChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setEditUserInformationFormData({
            ...editUserInformationFormData,
            allowTcs: event.target.checked
        });
    };

    const showUserInDokChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setEditUserInformationFormData({
            ...editUserInformationFormData,
            showUserInDok: event.target.checked
        });
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Edit User</DialogTitle>
            <DialogContent sx={{ width: '500px' }}>
                <TextField
                    sx={{ marginTop: '10px' }}
                    label="Short Name"
                    value={editUserInformationFormData.shortName || ''}
                    onChange={onShortNameChange}
                    fullWidth
                />
                <TextField
                    sx={{ marginTop: '10px' }}
                    label="DOK limit"
                    value={editUserInformationFormData.dokLimit || ''}
                    type="number"
                    onChange={onDokLimitChange}
                    fullWidth
                />
                <FormControlLabel
                    label="Laat gebruiker in dok zien"
                    control={<Checkbox checked={editUserInformationFormData.showUserInDok} onChange={showUserInDokChange} />}
                />
                <FormControlLabel
                    label="Gebruiker mag tcs'en"
                    control={<Checkbox checked={editUserInformationFormData.allowTcs} onChange={allowTcsChange} />}
                />
                {error &&
                    <Alert sx={{ marginTop: '10px' }} severity="error">{error}</Alert>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onEditProduct}>Edit User</Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditUserInformationDialog;