import React, { useState } from 'react';
import { Box, Button, Grid, TextField } from '@mui/material';
import { SearchUserParams, searchUsers } from '../../features/users/UsersSlice';
import { useDispatch } from 'react-redux';
import UsersSearchResultTable from './UsersSearchResultTable/UsersSearchResultTable';
import { hasRight } from '../../helpers/hasRight';
import { useAppSelector } from '../../hooks';
import { useNavigate } from 'react-router';
import CreateUserDialog from './CreateUserDialog/CreateUserDialog';


const UserSearchPage = (): JSX.Element => {
    const authenticated = useAppSelector((state) => state.user.authenticated);
    const navigate = useNavigate();
    const [searchUsersParams, setSearchUsersParams] = useState<SearchUserParams>({
        id: null,
        name: null,
        email: null
    });
    const [isCreateUserDialogOpen, setIsCreateUserDialogOpen] = useState(false);
    const dispatch = useDispatch();

    if (!authenticated) {
        return <></>;
    } else if (!hasRight('user_search')) {
        navigate('/');
    }

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchUsersParams({
            ...searchUsersParams,
            name: event.target.value || null
        });
    };

    const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchUsersParams({
            ...searchUsersParams,
            email: event.target.value || null
        });
    };

    const onIdChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchUsersParams({
            ...searchUsersParams,
            id: parseInt(event.target.value) || null
        });
    };

    const onSearch = (): void => {
        dispatch(searchUsers(searchUsersParams))
    }

    const onCreateProductClick = (): void => {
        setIsCreateUserDialogOpen(true);
    }

    return (
        <div>
            <CreateUserDialog 
                open={isCreateUserDialogOpen}
                onClose={() => setIsCreateUserDialogOpen(false)}
            />
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} xl={4}>
                    <TextField
                        label="Id"
                        type="number"
                        value={searchUsersParams.id || ''}
                        onChange={onIdChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                    <TextField
                        label="Name"
                        value={searchUsersParams.name || ''}
                        onChange={onNameChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                    <TextField
                        label="Email"
                        value={searchUsersParams.email || ''}
                        onChange={onEmailChange}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {hasRight('user_create') &&
                    <Button
                        sx={{ mt: 3, ml: 1 }}
                        onClick={onCreateProductClick}
                        variant="contained"
                    >
                        Create User
                    </Button>
                }
                <Button
                    variant="contained"
                    onClick={onSearch}
                    sx={{ mt: 3, ml: 1 }}
                >
                    Search
                </Button>
            </Box>
            <hr />
            <UsersSearchResultTable />
        </div>
    );
};

export default UserSearchPage;