import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { hasRight } from '../../helpers/hasRight';
import { useAppSelector } from '../../hooks';
import { useNavigate, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import RightsModule from './RightsModule/RightsModule';
import { getIndividualRoleInformation } from '../../features/roles/RolesSlice';
import RoleInformationModule from './RoleInformationModule/RoleInformationModule';

const RoleDetailPage = (): JSX.Element => {
    const authenticated = useAppSelector((state) => state.user.authenticated);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { roleId } = useParams<"roleId">();

    useEffect(() => {
        if (authenticated && roleId) {
            dispatch(getIndividualRoleInformation(parseInt(roleId)))
        }
    }, [authenticated, dispatch, roleId])

    if (!authenticated) {
        return <></>;
    } else if ((roleId && !parseInt(roleId)) || !hasRight('role_search')) {
        navigate('/');
        return <></>;
    }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <RoleInformationModule />
                </Grid>
                <Grid item xs={12} md={6}>
                    <RightsModule />
                </Grid>
            </Grid>
        </div>
    );
};

export default RoleDetailPage;