import { AnyAction, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios';
import { RootState } from '../../store';

// Define a type for the slice state
interface ProductSalesState {
    productSales: ProductSale[],
}

export interface ProductSale {
    product: string,
    amount: number
}

// Define the initial state using that type
const initialState: ProductSalesState = {
    productSales: []
}

export const productSalesSlice = createSlice({
    name: 'product_sales',
    initialState,
    reducers: {
        setProductSales: (state, action: PayloadAction<ProductSale[]>) => {
            state.productSales = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setProductSales } = productSalesSlice.actions
export default productSalesSlice.reducer

export const searchProductSales = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.get('/api/sales/products')
        .then((response: AxiosResponse<{ status: string, data: ProductSale[] }>) => {
            dispatch(setProductSales(response.data.data))
        })
        .catch((e) => {
            console.log(e);
        });
}