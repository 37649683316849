import React, { useState } from 'react';
import { Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useAppSelector } from '../../../hooks';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import { deleteRightFromRole } from '../../../features/roles/RolesSlice';
import AddRightDialog from './AddRightDialog/AddRightDialog';

const RightsModule = (): JSX.Element => {
    const role = useAppSelector((state) => state.roles.role);
    const dispatch = useDispatch();
    const [isAddRightDialogOpen, setIsAddRightDialogOpen] = useState(false);

    const onDeleteRightClick = (rightId: number): void => {
        if (role) {
            dispatch(deleteRightFromRole(role.id, rightId))
        }
    }

    const onAddRightDialogOpenClick = (): void => {
        setIsAddRightDialogOpen(true);
    }

    const onAddRightDialogClose = (): void => {
        setIsAddRightDialogOpen(false);
    }

    return (
        <div>
            <AddRightDialog
                open={isAddRightDialogOpen}
                onClose={onAddRightDialogClose}
            />
            <Grid container>
                <Grid item xs={6}>
                    <h2>Rights</h2>
                </Grid>
                <Grid item xs={6}>
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Right</TableCell>
                            <TableCell align="right">
                                <Button onClick={onAddRightDialogOpenClick}>Add role</Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {role?.rights.map((right) => (
                            <TableRow
                                key={right.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {right.name}
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton onClick={() => { onDeleteRightClick(right.id) }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default RightsModule