import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../hooks';
import { useNavigate } from 'react-router';
import { Alert } from '@mui/material';
import { createProduct, setCreatedProductError, setCreatedProductId } from '../../../features/products/ProductsSlice';

interface createProductDialogInterface {
    open: boolean,
    onClose: () => void,
}

interface CreateProductFormData {
    name: string | null,
    price: string | null
}

const CreateProductDialog = ({ open, onClose }: createProductDialogInterface): JSX.Element => {
    const dispatch = useDispatch();
    const [error, setError] = useState<string | null>(null);
    const createdProductId = useAppSelector((state) => state.products.createdProductId)
    const createdProductError = useAppSelector((state) => state.products.createdProductError)
    const navigate = useNavigate();
    const [createProductFormData, setCreateProductFormData] = useState<CreateProductFormData>({
        name: null,
        price: null
    });

    const onCreateRole = (() => {
        if (createProductFormData.name && createProductFormData.price && !isNaN(parseFloat(createProductFormData.price))) {
            dispatch(createProduct(createProductFormData.name, Math.floor(parseFloat(createProductFormData.price) * 100)))
        } else {
            setError('please enter a role name and a price.')
        }
    });

    useEffect(() => {
        if (createdProductId) {
            dispatch(setCreatedProductId(null));
            navigate('/products/' + createdProductId)
        }
    }, [createdProductId, dispatch, navigate])

    useEffect(() => {
        if (createdProductError) {
            dispatch(setCreatedProductError(null));
            setError(createdProductError);
        }
    }, [createdProductError, dispatch, navigate])

    const onProductNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setCreateProductFormData({
            ...createProductFormData,
            name: event.target.value
        });
    };

    const onProductPriceChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setCreateProductFormData({
            ...createProductFormData,
            price: event.target.value
        });
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Create Product</DialogTitle>
            <DialogContent sx={{ width: '500px' }}>
                <TextField
                    sx={{ mt: '10px' }}
                    label="Name"
                    value={createProductFormData.name || ''}
                    onChange={onProductNameChange}
                    fullWidth
                />
                <TextField
                    sx={{ marginTop: '10px' }}
                    label="Price"
                    value={createProductFormData.price || ''}
                    type="number"
                    onChange={onProductPriceChange}
                    fullWidth
                />
                {error &&
                    <Alert sx={{ marginTop: '10px' }} severity="error">{error}</Alert>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onCreateRole}>Create Product</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateProductDialog;