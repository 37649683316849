import { AnyAction, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import { RootState } from '../../store';

// Define a type for the slice state
interface TimeslotState {
    timeslots: Timeslot[],
    createdTimeslotId: number|null;
}

export interface Timeslot {
    id: number,
    name: string
    start_time: Date,
    end_time: Date,
    capacity: number,
    users: TimeslotUser[]
}

export interface TimeslotUser {
    id: number,
    name: string
}

export interface CreateTimeslotData {
    name: string | null,
    capacity: number | null,
    start_time: DateTime | null,
    end_time: DateTime | null,
    role_id: number | null
}

// Define the initial state using that type
const initialState: TimeslotState = {
    timeslots: [],
    createdTimeslotId: null
}

export const userSlice = createSlice({
    name: 'timeslot',
    initialState,
    reducers: {
        setTimeslots: (state, action: PayloadAction<Timeslot[]>) => {
            state.timeslots = action.payload;
        },
        replaceTimeslot: (state, action: PayloadAction<Timeslot>) => {
            state.timeslots = state.timeslots.map((timeslot: Timeslot) => {
                if (timeslot.id === action.payload.id) {
                    return action.payload;
                }
                return timeslot;
            })
        },
        removeTimeslot: (state, action: PayloadAction<number>) => {
            state.timeslots = state.timeslots.filter((timeslot) => timeslot.id !== action.payload);
        },
        setCreatedTimeslotId: (state, action: PayloadAction<number|null>) => {
            state.createdTimeslotId = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setTimeslots, replaceTimeslot, removeTimeslot, setCreatedTimeslotId } = userSlice.actions
export default userSlice.reducer

export const getTimeslots = (startDate: Date, endDate: Date): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.get('/api/timeslots', {
        params: {
            start_time: startDate,
            end_time: endDate
        }
    })
        .then((response: AxiosResponse<{ status: string, data: Timeslot[] }>) => {
            dispatch(setTimeslots(response.data.data))
        })
        .catch((e) => {
            console.log(e);
        });
}

export const getTimeslot = (timeslotId: number, replaceExisitingTimeslot: boolean = true): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.get(`/api/timeslots/${timeslotId}`)
        .then((response: AxiosResponse<{ status: string, data: Timeslot }>) => {
            if (replaceExisitingTimeslot) {
                dispatch(replaceTimeslot(response.data.data))
            } else {
                dispatch(setTimeslots([response.data.data]))
            }
        })
        .catch((e) => {
            console.log(e);
        });
}


export const subscribeUserToTimeslot = (userId: number, timeslotId: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.post(`/api/timeslots/${timeslotId}/users/${userId}`)
        .then((response: AxiosResponse<{ status: string, data: Timeslot[] }>) => {
            dispatch(getTimeslot(timeslotId));
        })
        .catch((e) => {
            console.log(e);
        });
}

export const unsubscribeUserToTimeslot = (userId: number, timeslotId: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.delete(`/api/timeslots/${timeslotId}/users/${userId}`)
        .then((response: AxiosResponse<{ status: string, data: Timeslot[] }>) => {
            dispatch(getTimeslot(timeslotId));
        })
        .catch((e) => {
            console.log(e);
        });
}

export const createTimeslot = (params: CreateTimeslotData): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.post(`/api/timeslots`, {
        ...params,
        start_time: params.start_time?.toFormat('yyyy-MM-dd HH:mm'),
        end_time: params.end_time?.toFormat('yyyy-MM-dd HH:mm'),
    })
        .then((response: AxiosResponse<{ status: string, data: number }>) => {
            dispatch(setCreatedTimeslotId(response.data.data));
        })
        .catch((e) => {
            console.log(e);
        });
}

export const deleteTimeslot = (timeslotId: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.delete(`/api/timeslots/` + timeslotId)
        .then((response: AxiosResponse<{ status: string }>) => {
            dispatch(removeTimeslot(timeslotId));
        })
        .catch((e) => {
            console.log(e);
        });
}