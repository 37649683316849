import React, { useState } from 'react';
import { useAppSelector } from '../../../hooks';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { hasRight } from '../../../helpers/hasRight';
import EditProductCategoryDialog from './EditProductCategoryDialog/EditProductCategoryDialog';
import { getIndividualProductInformation } from '../../../features/products/ProductsSlice';
import { useDispatch } from 'react-redux';

const ProductCategoryModule = (): JSX.Element => {
    const [isEditProdctDialogOpen, setIsEditProductDialogOpen] = useState(false);
    const product = useAppSelector((state) => state.products.product);
    const dispatch = useDispatch();

    if (!product) {
        return <></>;
    }

    const onEditProductCategoryClose = (): void => {
        setIsEditProductDialogOpen(false);
        dispatch(getIndividualProductInformation(product.id))    
    }

    return (
        <div>
            <EditProductCategoryDialog
                open={isEditProdctDialogOpen}
                onClose={onEditProductCategoryClose}
                productId={product?.id}
            />
            {hasRight('add_product_to_category') &&
                <div>
                    <h2>
                        Product Category
                        <IconButton onClick={() => setIsEditProductDialogOpen(true)} ><EditIcon /></IconButton>
                    </h2>
                </div>
            }
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Type</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {product?.product_product_categories.map((productProductCategory) => {
                            return (
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {productProductCategory.product_category.name}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {productProductCategory.product_category_type.name}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default ProductCategoryModule