import { Alert, Button, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { loginUser } from '../../features/authentication/userSlice';
import { useAppSelector } from '../../hooks';

interface LoginFormData {
    email: string|null,
    password: string|null
}

const LoginPage = (): JSX.Element => {
    const dispatch = useDispatch();
    const [loginFormData, setLoginFormData] = useState<LoginFormData>({
        email: null,
        password: null
    });
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const authenticated = useAppSelector((state) => state.user.authenticated);
    const navigate = useNavigate();

    useEffect(() => {
        if (authenticated) {
            navigate("/");
        }
    }, [authenticated, navigate])

    const validateFormData = (): string[] => {
        let messages: string[] = [];

        if (!loginFormData.email) {
            messages = messages.concat('Please enter an email.')
        }

        if (!loginFormData.password) {
            messages = messages.concat('Please enter a password.')
        }

        return messages;
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const messages = validateFormData();
        if (messages.length === 0) {
            dispatch(loginUser(loginFormData.email || '', loginFormData.password || ''));
        }
        setErrorMessages(messages);
    };

    const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoginFormData({
            ...loginFormData,
            email: event.target.value
        })
    }

    const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoginFormData({
            ...loginFormData,
            password: event.target.value
        })
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={3} >
                <Grid item sm={12}>
                    <TextField
                        label="Email"
                        type="email"
                        value={loginFormData.email || ''}
                        onChange={onEmailChange}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12}>
                    <TextField
                        label="Password"
                        type="password"
                        value={loginFormData.password || ''}
                        onChange={onPasswordChange}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12}>
                    {errorMessages.map((message: string) => {
                        return (
                            <Alert key={message} severity="error">{message}</Alert>
                        )
                    })}
                </Grid>
                <Grid item sm={12}>
                    <Button type="submit" variant="contained" color="primary">
                        Login
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default LoginPage;