import { AnyAction, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit'
import axios, { AxiosError, AxiosResponse } from 'axios';
import { RootState } from '../../store';
import {isBoolean} from "node:util";

type loadingState = 'loading' | 'idle' | 'failed' | 'success';

// Define a type for the slice state
interface UserState {
    state: loadingState,
    users: SearchUser[],
    user: IndividualUser | null,
    editUserState: loadingState
    editUserError: string | null,
    createdUserId: number|null,
    createUserError: string|null
}

export interface SearchUser {
    id: number,
    name: string,
    short_name: string
}

export interface SearchUserParams {
    id: number | null
    name: string | null,
    email: string | null,
    show_user_in_dok?: boolean | null | string
}

export interface IndividualUser {
    id: number,
    name: string,
    short_name: string,
    email: string,
    roles: IndividualUserRole[],
    dok_limit: number|null,
    dok_amount: number,
    telegram_token: {token: string}|null,
    dok_export: {[key: string]: number}
    allow_tcs: boolean,
    show_user_in_dok: boolean,
}

export interface IndividualUserRole {
    id: number,
    name: string
}

// Define the initial state using that type
const initialState: UserState = {
    state: 'idle',
    users: [],
    user: null,
    editUserError: null,
    editUserState: 'idle',
    createdUserId: null,
    createUserError: null
}


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        searchStarted: (state) => {
            state.state = 'loading';
        },
        searchFailed: (state) => {
            state.state = 'failed';
        },
        setUsers: (state, action: PayloadAction<SearchUser[]>) => {
            state.users = action.payload;
            state.state = 'success'
        },
        setUser: (state, action: PayloadAction<IndividualUser>) => {
            state.user = action.payload;
        },
        setEditUserError: (state, action: PayloadAction<string|null>) => {
            state.editUserError = action.payload;
            if (action.payload === null) {
                state.editUserState = 'idle';
            } else {
                state.editUserState = 'failed';    
            }
        },
        setEditUserState: (state, action: PayloadAction<loadingState>) => {
            state.editUserState = action.payload;
        },
        setCreatedUserId: (state, action: PayloadAction<number|null>) => {
            state.createdUserId = action.payload;
        },
        setCreateUserError: (state, action: PayloadAction<string|null>) => {
            state.createUserError = action.payload;
        },
    },
})

export const selectUserName = (state: RootState): string | null => state.user.userInformation && state.user.userInformation.name

// Action creators are generated for each case reducer function
export const { searchStarted, searchFailed, setUsers, setUser, setEditUserError, setEditUserState, setCreatedUserId, setCreateUserError } = userSlice.actions
export default userSlice.reducer


export const searchUsers = (searchParams: SearchUserParams): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch, getState) => {
    dispatch(searchStarted());

    if (typeof searchParams.show_user_in_dok === 'boolean') {
        searchParams.show_user_in_dok = searchParams.show_user_in_dok ? '1' : '0';
    }

    axios.get('/api/users', {
        params: {...searchParams}
    })
        .then((response: AxiosResponse<{ data: SearchUser[] }>) => {
            dispatch(setUsers(response.data.data));
        })
        .catch((e) => {
            dispatch(searchFailed())
            console.log(e);
        });
}

export const getIndividualUserInformation = (userId: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch, getState) => {
    dispatch(searchStarted());
    axios.get('/api/users/' + userId)
        .then((response: AxiosResponse<{ data: IndividualUser }>) => {
            dispatch(setUser(response.data.data));
        })
        .catch((e) => {
            dispatch(searchFailed())
            console.log(e);
        });
}

interface CreateUserResponseSuccess {
    status: 'success',
    data: number
}

interface CreateUserResponseError {
    status: 'error',
    message: string,
    errors: {[key: string]: string[]}
}

export const createUser = (name: string, email: string, password: string): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.post(`/api/users`, {
        name: name,
        email: email,
        password: password
    })
        .then((response: AxiosResponse<CreateUserResponseSuccess>) => {
            dispatch(setCreatedUserId(response.data.data));
        })
        .catch((e: AxiosError<CreateUserResponseError>) => {
            if (e.response) {
                const errorKeys = Object.keys(e.response.data.errors);
                console.log(errorKeys, e.response?.data.errors[errorKeys[0]][0]);
                if (errorKeys.length > 0) {
                    dispatch(setCreateUserError(e.response?.data.errors[errorKeys[0]][0] || null));
                }
            }
            console.log(e);
        });
}

export const editUserInformation = (userId: number, shortName: string, dokLimit: number, showUserInDok: boolean, allowTcs: boolean): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    dispatch(setEditUserState('loading'));
    axios.patch(`/api/users/${userId}`, {
        short_name: shortName,
        dok_limit: dokLimit,
        show_user_in_dok: showUserInDok,
        allow_tcs: allowTcs
    })
        .then((response: AxiosResponse) => {
            dispatch(setEditUserState('success'));
            dispatch(getIndividualUserInformation(userId));
        })
        .catch((e: AxiosError<{message: string}>) => {
            dispatch(setEditUserError(e.response?.data.message || null))
            console.log(e);
        });
}


export const deleteRoleFromUser = (userId: number, roleId: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.delete(`/api/users/${userId}/roles/${roleId}`)
        .then((response: AxiosResponse) => {
            dispatch(getIndividualUserInformation(userId));
        })
        .catch((e) => {
            dispatch(searchFailed())
            console.log(e);
        });
}

export const addRoleToUser = (userId: number, roleId: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.post(`/api/users/${userId}/roles/${roleId}`)
        .then((response: AxiosResponse) => {
            dispatch(getIndividualUserInformation(userId));
        })
        .catch((e) => {
            dispatch(searchFailed())
            console.log(e);
        });
}


export const regenerateTelegramToken = (userId: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.post(`/api/users/${userId}/regenerateToken`)
        .then((response: AxiosResponse) => {
            dispatch(getIndividualUserInformation(userId));
        })
        .catch((e) => {
            dispatch(searchFailed())
            console.log(e);
        });
}

