import React from 'react';
import { Grid } from '@mui/material';
import { hasRight } from '../../helpers/hasRight';
import { useAppSelector } from '../../hooks';
import { useNavigate } from 'react-router';
import ProductSalesModule from './ProductSalesModule/ProductSalesModule';
import PaymentsModule from './PaymentsModule/PaymentsModule';

const SalesPage = (): JSX.Element => {
    const authenticated = useAppSelector((state) => state.user.authenticated);
    const navigate = useNavigate();

    if (!authenticated) {
        return <></>;
    } else if (!hasRight('user_search')) {
        navigate('/');
        return <></>;
    }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <PaymentsModule />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ProductSalesModule />
                </Grid>
            </Grid>
        </div>
    );
};

export default SalesPage