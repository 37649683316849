import { configureStore } from '@reduxjs/toolkit'
import userReducer from './features/authentication/userSlice'
import timeslotReducer from './features/timeslots/timeslotSlice'
import usersReducer from './features/users/UsersSlice';
import rolesReducer from './features/roles/RolesSlice';
import rightsReducer from './features/rights/RightsSlice';
import productsReducer from './features/products/ProductsSlice';
import checkoutReducer from './features/products/CheckoutSlice';
import paymentMethodReducer from './features/payments/PaymentMethodSlice';
import salesReducer  from './features/sales/salesReducer';
import productCategoriesReducer from './features/products/ProductCategoriesSlice'
import ExportsReducer from './features/exports/ExportsSlice';

const store = configureStore({
    reducer: {
        user: userReducer,
        users: usersReducer,
        timeslot: timeslotReducer,
        roles: rolesReducer,
        rights: rightsReducer,
        products: productsReducer,
        checkout: checkoutReducer,
        paymentMethods: paymentMethodReducer,
        productCategories: productCategoriesReducer,
        sales: salesReducer,
        exports: ExportsReducer
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export default store;