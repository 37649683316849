import { UserInformationRight, UserInformationRole } from "../features/authentication/userSlice";
import store from "../store";

export const hasRight = (right: string): boolean => {
    return getRoles().includes('developer') || getRights().includes(right);
}

const getRoles = (): string[] => {
    return store.getState().user.userInformation?.roles.map((role: UserInformationRole) => role.name) || [];
}

const getRights = (): string[] => {
    let rights: string[] = [];
    store.getState().user.userInformation?.roles.forEach((role: UserInformationRole) => {
        role.rights.forEach((right: UserInformationRight) => {
            rights = rights.concat(right.name);
        })
    });
    return rights;
}