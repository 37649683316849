import React, { useEffect, useState } from 'react';
import { Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import axios, { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import { formatPrice } from '../../../helpers/formatters/formatPrice';
import DisplayOrderDialog from './DisplayOrderDialog/DisplayOrderDialog';
import { hasRight } from '../../../helpers/hasRight';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import { deleteOrder } from '../../../features/products/CheckoutSlice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../hooks';

interface OrdersModuleInterface {
    userId: number;
}

export interface UserOrder {
    id: number,
    created_at: string,
    order_rows: UserOrderOrderRows[]
    payments: UserOrderPayments[]
}

interface UserOrderOrderRows {
    id: number,
    quantity: number,
    price: number,
    product: { name: string }
}

interface UserOrderPayments {
    payment_method: { name: string }
}

const OrdersModule = ({ userId }: OrdersModuleInterface): JSX.Element => {
    const [orders, setOrders] = useState<UserOrder[]>([]);
    const [displayOrder, setDisplayOrder] = useState<UserOrder | null>(null);
    const [orderIdToDelete, setOrderIdToDelete] = useState<number | null>(null);
    const dispatch = useDispatch();
    const deleteOrderStatus = useAppSelector((state) => state.checkout.deleteOrderStatus);

    const getOrders = (id: number): void => {
        axios.get('/api/orders/' + id)
            .then((response: AxiosResponse<{ data: UserOrder[] }>) => {
                setOrders(response.data.data);
            })
    };

    useEffect(() => {
        switch (deleteOrderStatus) {
            case 'succes':
                setOrderIdToDelete(null);
                getOrders(userId);
                break;
            default:
                break;
        }
    }, [deleteOrderStatus, userId]);

    useEffect(() => {
        getOrders(userId)
    }, [userId])

    const onOrderIdToDelteDialogClose = (): void => {
        setOrderIdToDelete(null);
    };

    const onOrderIdToDeleteDialogSuccess = (): void => {
        if (orderIdToDelete) {
            dispatch(deleteOrder(orderIdToDelete));
        }
    };

    return (
        <div>
            <ConfirmDialog 
                open={!!orderIdToDelete}
                handleClose={onOrderIdToDelteDialogClose}
                handleSucces={onOrderIdToDeleteDialogSuccess}
                title="DeleteOrder"
                body="Are you sure you want to delete this order?"
            />
            {displayOrder &&
                <DisplayOrderDialog
                    onClose={() => setDisplayOrder(null)}
                    order={displayOrder}
                />
            }
            <Grid container>
                <Grid item xs={6}>
                    <h2>Orders</h2>
                </Grid>
                <Grid item xs={6}>
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Create Date</TableCell>
                            <TableCell>Payment Method</TableCell>
                            {hasRight('order_delete') && (
                                <TableCell>Delete Order</TableCell>
                            )}
                            <TableCell align="right">Total Amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders.map((order) => {
                            let totalPrice = 0;
                            order.order_rows.forEach((order_row) => {
                                totalPrice += order_row.quantity * order_row.price
                            });

                            return (
                                <TableRow
                                    key={order.id}
                                >
                                    <TableCell component="th" scope="row">
                                        {DateTime.fromISO(order.created_at).toFormat('y-LL-dd HH:mm')}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {order.payments.length > 0 ? order.payments[0].payment_method.name : ''}
                                    </TableCell>
                                    {hasRight('order_delete') && (
                                        <TableCell component="th" scope="row">
                                            <IconButton onClick={(): void => setOrderIdToDelete(order.id)}>
                                                <DeleteIcon color="error" />
                                            </IconButton>
                                        </TableCell>
                                    )}
                                    <TableCell align="right">
                                        <Button onClick={() => setDisplayOrder(order)}>{formatPrice(totalPrice)}</Button>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default OrdersModule;