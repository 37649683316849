import React, { useState } from 'react';
import { useAppSelector } from '../../../hooks';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { formatPrice } from '../../../helpers/formatters/formatPrice';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import EditProductDialog from './EditProductDialog/EditProductDialog';
import { hasRight } from '../../../helpers/hasRight';

const ProductInformationModule = (): JSX.Element => {
    const [isEditProdctDialogOpen, setIsEditProductDialogOpen] = useState(false);
    const product = useAppSelector((state) => state.products.product);
    
    return (
        <div>
            <EditProductDialog
                open={isEditProdctDialogOpen}
                onClose={() => setIsEditProductDialogOpen(false)}
            />
            {hasRight('product_edit') &&
                <div>
                    <h2>
                        Product Information
                        <IconButton onClick={() => setIsEditProductDialogOpen(true)} ><EditIcon /></IconButton>
                    </h2>
                </div>
            }
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Id
                            </TableCell>
                            <TableCell align="right">{product?.id}</TableCell>
                        </TableRow>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Name
                            </TableCell>
                            <TableCell align="right">{product?.name}</TableCell>
                        </TableRow>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                price
                            </TableCell>
                            <TableCell align="right">{product && formatPrice(product.price)}</TableCell>
                        </TableRow>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                TCS Price
                            </TableCell>
                            <TableCell align="right">{product?.tcs_price && formatPrice(product.tcs_price)}</TableCell>
                        </TableRow>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Is active
                            </TableCell>
                            <TableCell align="right">{product && (product.active ? 'Yes' : 'No')}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default ProductInformationModule