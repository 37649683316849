import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, Autocomplete, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../../../hooks';
import { searchUsers } from '../../../../features/users/UsersSlice';
import { clearCheckout, createOrder } from '../../../../features/products/CheckoutSlice';
import { searchPaymentMethods } from '../../../../features/payments/PaymentMethodSlice';

interface AddRightDialogInterface {
    open: boolean,
    onClose: () => void
}

interface AutoCompleteOption {
    id: number,
    label: string
}

const CreateorderDialog = ({ open, onClose }: AddRightDialogInterface): JSX.Element => {
    const dispatch = useDispatch();
    const [value, setValue] = useState<AutoCompleteOption | null>(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<AutoCompleteOption[]>([]);
    const users = useAppSelector((state) => state.users.users)
    const paymentMethods = useAppSelector((state) => state.paymentMethods.paymentMethods)
    const checkout = useAppSelector((state) => state.checkout);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<number | null>(null);
    const [error, setError] = useState<string | null>(null);
    const refContainer = useRef<HTMLInputElement>(null);

    useEffect(() => {
        dispatch(searchUsers({ id: null, name: null, email: null, show_user_in_dok: true}));
        dispatch(searchPaymentMethods());
    }, [dispatch]);

    useEffect(() => {
        switch (checkout.status) {
            case 'succes':
                dispatch(clearCheckout())
                onClose();
                setInputValue('');
                setValue(null);
                setError(null);
                break;
            case 'error': 
                setError(checkout.errorMessage);
                break;
            case 'pending': 
                break;
            default:
                setError(null);
                break;
        }
    }, [checkout.errorMessage, checkout.status, dispatch, onClose])

    useEffect(() => {
        const newUsers = users.map((user) => {
            return {
                id: user.id,
                label: `(${user.short_name}) - ${user.name}`
            };
        });
        setOptions(newUsers);
    }, [users]);

    useEffect(() => {
        if (paymentMethods.length === 1) {
            setSelectedPaymentMethod(paymentMethods[0].id);
        }
    }, [paymentMethods])

    const onSelectPaymentMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedPaymentMethod(parseInt((event.target as HTMLInputElement).value));
    };

    const onCreateOrder = (() => {
        if (!value || !selectedPaymentMethod) {
            setError('Please select an user and payment method.')
            return;
        }
        dispatch(createOrder(value.id, selectedPaymentMethod, checkout.products));
    });

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Create Order</DialogTitle>
            <DialogContent sx={{ width: '500px' }}>
                <Autocomplete
                    sx={{ marginTop: 1 }}
                    value={value}
                    onChange={(event: any, newValue: AutoCompleteOption | null) => {
                        setValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    options={options}
                    autoSelect
                    renderInput={(params) => <TextField {...params} autoFocus label="User" inputRef={refContainer} />}
                />
                <FormControl component="fieldset" sx={{ marginTop: 1 }}>
                    <FormLabel component="legend">Payment Method</FormLabel>
                    <RadioGroup
                        aria-label="Payment Method"
                        name="radio-buttons-group"
                        value={selectedPaymentMethod}
                        onChange={onSelectPaymentMethodChange}
                    >
                        {paymentMethods.map((paymentMethod) => {
                            return (
                                <FormControlLabel
                                    key={paymentMethod.id}
                                    value={paymentMethod.id}
                                    control={<Radio />}
                                    label={paymentMethod.name}
                                />
                            )
                        })}
                    </RadioGroup>
                </FormControl>
                {error &&
                    <Alert sx={{ marginTop: 1 }} severity="error">{error}</Alert>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onCreateOrder} disabled={checkout.status === 'pending'}>Create Order</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateorderDialog;