import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, styled, TextField } from '@mui/material';
import { DatePicker, PickersDay, PickersDayProps } from '@mui/lab';
import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../hooks';
import { getTimeslots } from '../../../features/timeslots/timeslotSlice';
import { getDaySearchOption, getWeekSearchOption } from './helperFunctions/convertDateSearchOptions';

type TimeslotSearchOption = 'day' | 'week' | 'range';

type CustomPickerDayProps = PickersDayProps<DateTime> & {
    dayIsBetween: boolean;
    isFirstDay: boolean;
    isLastDay: boolean;
};

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) =>
        prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
    ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.dark,
        },
    }),
    ...(isFirstDay && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    }),
    ...(isLastDay && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    }),
})) as React.ComponentType<CustomPickerDayProps>;

export default function TimeslotSearchOptions(): JSX.Element {
    const [weekValue, setWeekValue] = React.useState<DateTime>(DateTime.now());
    const [dayValue, setDayValue] = useState<DateTime>(DateTime.now());
    const [rangeValues, setRangeValues] = useState<[DateTime, DateTime]>([DateTime.now(), DateTime.now()]);
    const [timeslotSearchOption, setTimeslotSeachOption] = useState<TimeslotSearchOption>('day');
    const authenticated = useAppSelector((state) => state.user.authenticated);
    const dispatch = useDispatch();

    useEffect(() => {
        if (authenticated && dayValue) {
            let searchTimeslotValues: [DateTime, DateTime] | null = null;
            switch (timeslotSearchOption) {
                case 'day':
                    searchTimeslotValues = getDaySearchOption(dayValue);                    
                    break;
                case 'week':
                    searchTimeslotValues = getWeekSearchOption(weekValue);
                    break;
                case 'range':
                    searchTimeslotValues = rangeValues;
            }
            if (searchTimeslotValues) {
                dispatch(getTimeslots(searchTimeslotValues[0].toJSDate(), searchTimeslotValues[1].toJSDate()));
            }
        }
    }, [dispatch, authenticated, dayValue, timeslotSearchOption, weekValue, rangeValues]);

    const onSearchOptionChange = (event: SelectChangeEvent) => {
        setTimeslotSeachOption(event.target.value as TimeslotSearchOption);
    };

    const renderWeekPickerDay = (
        date: DateTime,
        selectedDates: Array<DateTime | null>,
        pickersDayProps: PickersDayProps<DateTime>,
    ) => {
        if (!weekValue) {
            return <PickersDay {...pickersDayProps} />;
        }
        const start = weekValue.startOf('week').minus({ days: 1 });
        const end = weekValue.endOf('week');

        const dayIsBetween = start < date && date < end;
        const isFirstDay = date.hasSame(start, 'day');
        const isLastDay = date.hasSame(end, 'day');

        return (
            <CustomPickersDay
                {...pickersDayProps}
                dayIsBetween={dayIsBetween}
                isFirstDay={isFirstDay}
                isLastDay={isLastDay}
            />
        );
    };

    return (
        <>
            <FormControl sx={{ width: 200 }}>
                <InputLabel id="demo-simple-select-label">Search Option</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={timeslotSearchOption}
                    label="Age"
                    onChange={onSearchOptionChange}
                    sx={{ mr: 1 }}
                >
                    <MenuItem value={'day'}>Day</MenuItem>
                    <MenuItem value={'week'}>Week</MenuItem>
                    <MenuItem value={'range'}>Range </MenuItem>
                </Select>
            </FormControl>

            {timeslotSearchOption === 'day' &&
                <DatePicker
                    label="Date"
                    value={dayValue}
                    onChange={(newDate) => {
                        if (newDate) {
                            setDayValue(newDate);
                        }
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            }

            {timeslotSearchOption === 'week' &&
                <DatePicker
                    label="Week picker"
                    value={weekValue}
                    onChange={(newValue) => {
                        if (newValue) {
                            setWeekValue(newValue);
                        }
                    }}
                    renderDay={renderWeekPickerDay}
                    renderInput={(params) => <TextField {...params} />}
                    inputFormat="'Week of' MMM d"
                />
            }

            {timeslotSearchOption === 'range' &&
                <>
                    <DatePicker
                        label="Start Date"
                        value={rangeValues[0]}
                        onChange={(newValue) => {
                            if (newValue) {
                                setRangeValues([newValue.startOf('day'), rangeValues[1]]);
                            }
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <DatePicker
                        label="End Date"
                        value={rangeValues[1]}
                        onChange={(newValue) => {
                            if (newValue) { 
                                setRangeValues([rangeValues[0], newValue.endOf('day')]);
                            }
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </>
            }
        </>
    )
}
