import { AnyAction, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios';
import { RootState } from '../../store';
import { DateTime } from 'luxon';

// Define a type for the slice state
interface ExportState {
    dokExport: string|null,
    dokResets: Date[]
}

// Define the initial state using that type
const initialState: ExportState = {
    dokExport: null,
    dokResets: []
}

export const exportsSlice = createSlice({
    name: 'exports',
    initialState,
    reducers: {
        setDokExport: (state, action: PayloadAction<string|null>) => {
            state.dokExport = action.payload;
        },
        setDokResets: (state, action: PayloadAction<Date[]>) => {
            state.dokResets = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setDokExport, setDokResets } = exportsSlice.actions
export default exportsSlice.reducer
  
export const getDokExport = (createdAfter: DateTime, createdBefore: DateTime, tcsOrders: boolean): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.get('/api/exports/dok', {
        params: {
            created_after: createdAfter.toFormat('yyyy-MM-dd HH:mm'), 
            created_before: createdBefore.toFormat('yyyy-MM-dd HH:mm'),
            tcs_orders: tcsOrders ? 1 : 0
        }
    })
        .then((response: AxiosResponse<{status: string, data: string}>) => {
            dispatch(setDokExport(response.data.data))
        })
        .catch((e) => {
            console.log(e);
        });
}

export const getDokResets = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.get('/api/dok/resets')
        .then((response: AxiosResponse<{status: string, data: string[]}>) => {
            dispatch(setDokResets(response.data.data.map((string) => new Date(string))));
        })
        .catch((e) => {
            console.log(e);
        });
}