import { AnyAction, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios';
import { RootState } from '../../store';

// Define a type for the slice state
interface ProductState {
    products: Product[],
    product: IndividualProduct | null,
    createdProductId: number | null,
    createdProductError: string | null
}

export interface IndividualProduct {
    id: number,
    name: string,
    price: number,
    tcs_price: number|null,
    active: boolean,
    product_product_categories: ProductProductCategory[]
}

interface ProductProductCategory {
    product_category_type: {name: string},
    product_category: ProductCategory
}

interface ProductCategory {
    name: string,
}

export interface SearchProductParams {
    id?: number
    name?: string,
    active?: string
}

export interface Product {
    id: number,
    name: string,
    price: number,
    tcs_price: number,
    top_5: boolean,
    product_product_categories: ProductProductCategory[]
}

// Define the initial state using that type
const initialState: ProductState = {
    products: [],
    product: null,
    createdProductError: null,
    createdProductId: null
}

export const rolesSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {
        setProducts: (state, action: PayloadAction<Product[]>) => {
            state.products = action.payload;
        },
        setProduct: (state, action: PayloadAction<IndividualProduct>) => {
            state.product = action.payload;
        },
        setCreatedProductError: (state, action: PayloadAction<string | null>) => {
            state.createdProductError = action.payload;
        },
        setCreatedProductId: (state, action: PayloadAction<number | null>) => {
            state.createdProductId = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setProduct, setProducts, setCreatedProductError, setCreatedProductId } = rolesSlice.actions
export default rolesSlice.reducer

export const searchProducts = (params: SearchProductParams): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.get('/api/products', {
        params: params
    })
        .then((response: AxiosResponse<{ status: string, data: Product[] }>) => {
            dispatch(setProducts(response.data.data))
        })
        .catch((e) => {
            console.log(e);
        });
}

export const getIndividualProductInformation = (roleId: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.get('/api/products/' + roleId)
        .then((response: AxiosResponse<{ status: string, data: IndividualProduct }>) => {
            dispatch(setProduct(response.data.data))
        })
        .catch((e) => {
            console.log(e);
        });
}

interface CreateProductResponseSuccess {
    status: 'success',
    data: number
}

interface CreateProductResponseError {
    status: 'error',
    message: string
}

type createProductResponse = CreateProductResponseSuccess | CreateProductResponseError

export const createProduct = (name: string, price: number): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.post(`/api/products`, {
        name: name,
        price: price
    })
        .then((response: AxiosResponse<createProductResponse>) => {
            if (response.data.status === 'success' ) {
                dispatch(setCreatedProductId(response.data.data));
            } else if (response.data.status === 'error') {
                dispatch(setCreatedProductError(response.data.message))
            }
        })
        .catch((e) => {
            console.log(e);
        });
}

export const editProduct = (id: number, name: string, price: number, tcs_price: number|null, active: boolean): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch, getState) => {
    axios.patch(`/api/products/` + id, {
        name: name,
        price: price,
        tcs_price: tcs_price,
        active: active
    })
        .then((response: AxiosResponse<createProductResponse>) => {
            if (response.data.status === 'success' ) {
                dispatch(setCreatedProductId(response.data.data));
            } else if (response.data.status === 'error') {
                dispatch(setCreatedProductError(response.data.message))
            }
        })
        .catch((e) => {
            console.log(e);
        });
}