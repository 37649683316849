import React, { useState } from 'react';
import { useAppSelector } from '../../../hooks';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { formatPrice } from '../../../helpers/formatters/formatPrice';
import EditIcon from '@mui/icons-material/Edit';
import { hasRight } from '../../../helpers/hasRight';
import EditUserInformationDialog from './EditUserInformationDialog/EditUserInformationDialog';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import { regenerateTelegramToken } from '../../../features/users/UsersSlice';
import { useDispatch } from 'react-redux';
import RefreshIcon from '@mui/icons-material/Refresh';

const PersonalInformation = (): JSX.Element => {
    const [showEditUserInformationDialog, setShowEditUserInformationDialog] = useState(false);
    const user = useAppSelector((state) => state.users.user);
    const [isRefreshTokenDialogOpen, setIsRefreshTokenDialogOpen] = useState(false);
    const dispatch = useDispatch();

    const onRefreshClick = (): void => {
        setIsRefreshTokenDialogOpen(true);
    }

    return (
        <div>
            <ConfirmDialog 
                open={isRefreshTokenDialogOpen}
                body="Are you sure you want to refresh the telegram token. You will need to manually reconnect."
                handleClose={(): void => setIsRefreshTokenDialogOpen(false)}
                handleSucces={(): void => {
                    if (user) {
                        dispatch(regenerateTelegramToken(user?.id))
                        setIsRefreshTokenDialogOpen(false);
                    }
                }}
            />
            <EditUserInformationDialog
                open={showEditUserInformationDialog}
                onClose={() => setShowEditUserInformationDialog(false)}
            />
            <h2>
                Personal Information
                {hasRight('user_edit') && (
                    <IconButton onClick={() => setShowEditUserInformationDialog(true)} ><EditIcon /></IconButton>
                )}
            </h2>
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Id
                            </TableCell>
                            <TableCell align="right">{user?.id}</TableCell>
                        </TableRow>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Name
                            </TableCell>
                            <TableCell align="right">{user?.name}</TableCell>
                        </TableRow>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Short Name
                            </TableCell>
                            <TableCell align="right">{user?.short_name}</TableCell>
                        </TableRow>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Email
                            </TableCell>
                            <TableCell align="right">{user?.email}</TableCell>
                        </TableRow>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Dok (deze maand)
                            </TableCell>
                            <TableCell align="right">{formatPrice(user?.dok_amount || 0)}{user?.dok_limit && '/' + formatPrice(user.dok_limit)}</TableCell>
                        </TableRow>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Laat gebruiker in dok zien
                            </TableCell>
                            <TableCell align="right">{user?.show_user_in_dok ? 'Ja' : 'Nee'}</TableCell>
                        </TableRow>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Gebruiker mag tcs'en
                            </TableCell>
                            <TableCell align="right">{user?.allow_tcs ? 'Ja' : 'Nee'}</TableCell>
                        </TableRow>
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                Telegram token
                            </TableCell>
                            <TableCell align="right">
                                {user?.telegram_token?.token}
                                <IconButton onClick={() => {onRefreshClick()}}>
                                    <RefreshIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default PersonalInformation