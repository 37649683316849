import React, { useState } from 'react';
import { Box, Button, Grid, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { hasRight } from '../../helpers/hasRight';
import { useAppSelector } from '../../hooks';
import { useNavigate } from 'react-router';
import { searchRoles, SearchRolesParams } from '../../features/roles/RolesSlice';
import RolesSearchResultsTable from './RolesSearchResultsTable/RolesSearchResultsTable';
import CreateRoleDialog from './CreateRoleDialog/CreateRoleDialog';


const RoleSearchPage = (): JSX.Element => {
    const authenticated = useAppSelector((state) => state.user.authenticated);
    const navigate = useNavigate();
    const [searchRolesParams, setSearchRolesParams] = useState<SearchRolesParams>({
        id: null,
        name: null
    });
    const [isCreateRoleDialogOpen, setIsCreateRoleDialogOpen] = useState(false);
    const dispatch = useDispatch();

    if (!authenticated) {
        return <></>;
    } else if (!hasRight('role_search')) {
        navigate('/');
    }

    const onIdChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchRolesParams({
            ...searchRolesParams,
            id: parseInt(event.target.value) || null
        });
    };

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchRolesParams({
            ...searchRolesParams,
            name: event.target.value || null
        });
    };

    const onSearch = (): void => {
        dispatch(searchRoles(searchRolesParams))
    }

    const onCreateRoleClick = (): void => {
        setIsCreateRoleDialogOpen(true);
    }

    return (
        <div>
            <CreateRoleDialog
                open={isCreateRoleDialogOpen}
                onClose={() => { setIsCreateRoleDialogOpen(false) }}
            />
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} xl={4}>
                    <TextField
                        label="Id"
                        type="number"
                        value={searchRolesParams.id || ''}
                        onChange={onIdChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                    <TextField
                        label="Name"
                        value={searchRolesParams.name || ''}
                        onChange={onNameChange}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {hasRight('role_create') &&
                    <Button
                        sx={{ mt: 3, ml: 1 }}
                        onClick={onCreateRoleClick}
                        variant="contained"
                    >
                        Create Role
                    </Button>
                }
                <Button
                    variant="contained"
                    onClick={onSearch}
                    sx={{ mt: 3, ml: 1 }}
                >
                    Search
                </Button>
            </Box>
            <hr />
            <RolesSearchResultsTable />
        </div>
    );
};

export default RoleSearchPage;