import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { hasRight } from '../../helpers/hasRight';
import { useAppSelector } from '../../hooks';
import { useNavigate, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { getIndividualProductInformation } from '../../features/products/ProductsSlice';
import ProductInformationModule from './ProductInformationModule/ProductInformationModule';
import ProductCategoryModule from './ProductCategoryModule/ProductCategoryModule';

const ProductDetailPage = (): JSX.Element => {
    const authenticated = useAppSelector((state) => state.user.authenticated);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {productId} = useParams<"productId">();

    useEffect(() => {
        if (authenticated && productId) {
            dispatch(getIndividualProductInformation(parseInt(productId)))
        }
    }, [authenticated, dispatch, productId])

    if (!authenticated) {
        return <></>;
    } else if ((productId && !parseInt(productId)) || !hasRight('product_search')) {
        navigate('/');
        return <></>;   
    }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <ProductInformationModule />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ProductCategoryModule />
                </Grid>
            </Grid>
        </div>
    );
};

export default ProductDetailPage