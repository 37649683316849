import React, { useState } from 'react';
import { Autocomplete, Box, Button, Grid, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { hasRight } from '../../helpers/hasRight';
import { useAppSelector } from '../../hooks';
import { useNavigate } from 'react-router';
import { SearchProductParams, searchProducts } from '../../features/products/ProductsSlice';
import ProductsSearchResultTable from './ProductsSearchResultTable/ProductsSearchResultTable';
import CreateProductDialog from './CreateProductDialog/CreateProductDialog';

const ProductSearchPage = (): JSX.Element => {
    const authenticated = useAppSelector((state) => state.user.authenticated);
    const navigate = useNavigate();
    const [searchProductsParams, setSearchProductsParams] = useState<SearchProductParams>({});
    const [isCreateProductDialogOpen, setIsCreateProductDialogOpen] = useState(false);

    const [inputValue, setInputValue] = useState('');

    const dispatch = useDispatch();

    if (!authenticated) {
        return <></>;
    } else if (!hasRight('product_search')) {
        navigate('/');
        return <></>;
    }

    const onIdChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchProductsParams({
            ...searchProductsParams,
            id: parseInt(event.target.value) || undefined
        });
    };

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchProductsParams({
            ...searchProductsParams,
            name: event.target.value || undefined
        });
    };

    const onSearch = (): void => {
        dispatch(searchProducts(searchProductsParams))
    }

    const onCreateProductClick = (): void => {
        setIsCreateProductDialogOpen(true);
    }

    return (
        <div>
            <CreateProductDialog 
                open={isCreateProductDialogOpen}
                onClose={() => setIsCreateProductDialogOpen(false)}
            />
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} xl={4}>
                    <TextField
                        label="Id"
                        type="number"
                        value={searchProductsParams.id || ''}
                        onChange={onIdChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                    <TextField
                        label="Name"
                        value={searchProductsParams.name || ''}
                        onChange={onNameChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                    <Autocomplete
                        value={searchProductsParams.active !== undefined ? (searchProductsParams.active === '1' ? 'Yes' : 'No') : ''}
                        onChange={(event: any, newValue: string | null) => {
                            if (newValue === 'Yes' || newValue === 'No') {
                                setSearchProductsParams({
                                    ...searchProductsParams,
                                    active: newValue === 'Yes' ? '1' : '0'
                                });
                            } else {
                                setSearchProductsParams({
                                    ...searchProductsParams,
                                    active: undefined
                                });
                            }
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        options={['Yes', 'No']}
                        renderInput={(params) => <TextField {...params} label="Active" />}
                    />
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {hasRight('product_create') &&
                    <Button
                        sx={{ mt: 3, ml: 1 }}
                        onClick={onCreateProductClick}
                        variant="contained"
                    >
                        Create Product
                    </Button>
                }
                <Button
                    variant="contained"
                    onClick={onSearch}
                    sx={{ mt: 3, ml: 1 }}
                >
                    Search
                </Button>
            </Box>
            <hr />
            <ProductsSearchResultTable />
        </div>
    );
};

export default ProductSearchPage;