import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { verifyOauthCode } from '../../features/authentication/userSlice';

const OauthReturnPage = (): JSX.Element => {
    const dispatch = useDispatch();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');
    const state = urlParams.get('state');

    useEffect(() => {
        if (code) {
            switch (state) {
                case 'app':
                    document.location.href = `intent:#Intent;action=com.example.cleopatra_member_portal.openfromweb;category=android.intent.category.DEFAULT;category=android.intent.category.BROWSABLE;S.token=${code};end`
                    break;
                case 'no_auth':
                    break;
                default:
                    dispatch(verifyOauthCode(code));

            }
        }
    }, [code, dispatch, state])

    return (
        <>
            karma
        </>
    );
};

export default OauthReturnPage;