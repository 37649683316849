import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Alert, Checkbox, FormControlLabel } from '@mui/material';
import { useAppSelector } from '../../../../hooks';
import { editProduct, getIndividualProductInformation, setCreatedProductError, setCreatedProductId } from '../../../../features/products/ProductsSlice';

interface createProductDialogInterface {
    open: boolean,
    onClose: () => void,
}

interface EditProductFormData {
    id: number | null,
    name: string | null,
    price: string | null,
    tcs_price: string | null,
    active: boolean
}

const EditProductDialog = ({ open, onClose }: createProductDialogInterface): JSX.Element => {
    const dispatch = useDispatch();
    const [error, setError] = useState<string | null>(null);
    const createdProductId = useAppSelector((state) => state.products.createdProductId)
    const createdProductError = useAppSelector((state) => state.products.createdProductError)
    const individualProduct = useAppSelector((state) => state.products.product)
    const [editProductFormData, setEditProductFormData] = useState<EditProductFormData>({
        id: null,
        name: null,
        price: null,
        tcs_price: null,
        active: true
    });

    useEffect(() => {
        if (createdProductId) {
            dispatch(setCreatedProductId(null));
            dispatch(getIndividualProductInformation(createdProductId));
            onClose();
        }
    }, [createdProductId, dispatch, onClose])

    useEffect(() => {
        if (createdProductError) {
            dispatch(setCreatedProductError(null));
            setError(createdProductError);
        }
    }, [createdProductError, dispatch])

    useEffect(() => {
        if (individualProduct) {
            setEditProductFormData({
                id: individualProduct.id,
                name: individualProduct.name,
                price: (individualProduct.price / 100).toString(),
                tcs_price: individualProduct.tcs_price ? (individualProduct.tcs_price / 100).toString() : null,
                active: !!individualProduct.active
            });
        }
    }, [individualProduct])

    const onEditProduct = (() => {
        if (
            editProductFormData.id 
            && editProductFormData.name
            && editProductFormData.price 
            && !isNaN(parseFloat(editProductFormData.price))
        ) {
            dispatch(editProduct(
                editProductFormData.id,
                editProductFormData.name,
                parseFloat(editProductFormData.price) * 100,
                editProductFormData.tcs_price ? (parseFloat(editProductFormData.tcs_price) * 100) : null,
                editProductFormData.active
            )
            )
        } else {
            setError('please enter a role name and a price.')
        }
    });

    const onProductNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setEditProductFormData({
            ...editProductFormData,
            name: event.target.value
        });
    };

    const onProductPriceChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setEditProductFormData({
            ...editProductFormData,
            price: event.target.value
        });
    };

    const onProductTcsPriceChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setEditProductFormData({
            ...editProductFormData,
            tcs_price: event.target.value
        });
    };

    const onActiveChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setEditProductFormData({
            ...editProductFormData,
            active: event.target.checked
        });
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Edit Product</DialogTitle>
            <DialogContent sx={{ width: '500px' }}>
                <TextField
                    sx={{ marginTop: '10px' }}
                    label="Name"
                    value={editProductFormData.name || ''}
                    onChange={onProductNameChange}
                    fullWidth
                />
                <TextField
                    sx={{ marginTop: '10px' }}
                    label="Price"
                    value={editProductFormData.price || ''}
                    type="number"
                    onChange={onProductPriceChange}
                    fullWidth
                />
                <TextField
                    sx={{ marginTop: '10px' }}
                    label="TCS Price"
                    value={editProductFormData.tcs_price || ''}
                    type="number"
                    onChange={onProductTcsPriceChange}
                    fullWidth
                />
                <FormControlLabel 
                    label="Active"
                    control={<Checkbox checked={editProductFormData.active} onChange={onActiveChange} />}
                />
                {error &&
                    <Alert sx={{ marginTop: '10px' }} severity="error">{error}</Alert>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onEditProduct}>Edit Product</Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditProductDialog;