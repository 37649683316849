import React from 'react';
import { Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { SearchUser } from '../../../features/users/UsersSlice';
import { useAppSelector } from '../../../hooks';
import { useNavigate } from 'react-router';


const UsersSearchResultTable = (): JSX.Element => {
    const users = useAppSelector((state) => state.users.users);
    const navigate = useNavigate();

    const onuserIdClick = (userId: number): void => {
        navigate('/users/' + userId);
    }

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Name</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user: SearchUser) => (
                        <TableRow
                            key={user.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell
                                component="th"
                                scope="row"
                            >
                                {user.id}
                            </TableCell>
                            <TableCell>
                                <Link
                                    onClick={() => { onuserIdClick(user.id) }}
                                    underline="hover"
                                >
                                    {user.name}
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default UsersSearchResultTable;