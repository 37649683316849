import { AnyAction, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios';
import { RootState } from '../../store';

// Define a type for the slice state
interface RightState {
    rights: Right[],
}

export interface Right {
    id: number,
    name: string
}

// Define the initial state using that type
const initialState: RightState = {
    rights: []
}

export const rightsSlice = createSlice({
    name: 'rights',
    initialState,
    reducers: {
        setRights: (state, action: PayloadAction<Right[]>) => {
            state.rights = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setRights } = rightsSlice.actions
export default rightsSlice.reducer
  
export const getAllRights = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    axios.get('/api/rights')
        .then((response: AxiosResponse<{status: string, data: Right[]}>) => {
            dispatch(setRights(response.data.data))
        })
        .catch((e) => {
            console.log(e);
        });
}