import React, { useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../hooks';
import { getTimeslot, Timeslot } from '../../features/timeslots/timeslotSlice';
import TimeslotCard from './TimeslotCard/TimeslotCard';
import { useNavigate, useParams } from 'react-router';
import { hasRight } from '../../helpers/hasRight';
import TimeslotSearchOptions from './TimeslotSearchOptions/TimeslotSearchOptions';

const TimeslotPage = (): JSX.Element => {
    const navigate = useNavigate();
    const timeslots = useAppSelector((state) => state.timeslot.timeslots);
    const authenticated = useAppSelector((state) => state.user.authenticated);
    const dispatch = useDispatch();
    const { timeslotId } = useParams<"timeslotId">();

    useEffect(() => {
        if (authenticated && timeslotId) {
            dispatch(getTimeslot(parseInt(timeslotId), false));
        }
    }, [authenticated, dispatch, timeslotId])

    if (!authenticated) {
        return <></>;
    } else if (!hasRight('timeslot_search')) {
        navigate('/');
        return <></>;
    }

    const onCreateTimeslotClick = (): void => {
        navigate('/timeslots/create');
    }

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item sm={12} md={hasRight('timeslot_create') ? 8 : 12}>
                    {!timeslotId &&
                        <TimeslotSearchOptions />
                    }
                </Grid>
                {hasRight('timeslot_create') &&
                    <Grid item sm={12} md={4} justifyContent="flex-end">
                        <Button
                            variant="contained"
                            onClick={onCreateTimeslotClick}
                            sx={{ float: 'right' }}
                        >
                            Create Timeslot
                        </Button>
                    </Grid>
                }
                {timeslots.map((timeslot: Timeslot) => {
                    return (
                        <TimeslotCard key={timeslot.id} timeslot={timeslot} />
                    )
                })}
            </Grid>
        </div >
    );
};

export default TimeslotPage;