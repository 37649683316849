import React from 'react';
import {
    Routes,
    Route
} from "react-router-dom";
import CreateTimeslotPage from '../CreateTimeslotPage/CreateTimeslotPage';
import DokPage from '../DokPage/DokPage';
import ExportDokPage from '../ExportDokPage/ExportDokPage';
import LoginPage from '../LoginPage/LoginPage';
import OauthReturnPage from '../OauthReturnPage/OauthReturnPage';
import ProductDetailPage from '../ProductDetailPage/ProductDetailPage';
import ProductSearchPage from '../ProductSearchPage/ProductSearchPage';
import RoleDetailPage from '../RoleDetailPage/RoleDetailPage';
import RoleSearchPage from '../RoleSearchPage/RoleSearchPage';
import SalesPage from '../SalesPage/SalesPages';
import TimeslotPage from '../TimeslotPage/TimeslotPage';
import UserDetailPage from '../UserDetailPage/UserDetailPage';
import UserSearchPage from '../UserSearchPage/UserSearchPage';

const ApplicationRouter = (): JSX.Element => {
    return (
        <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/sales" element={<SalesPage />} />
            <Route path="/dok" element={<DokPage />} />
            <Route path="/products/:productId" element={<ProductDetailPage />} />
            <Route path="/products" element={<ProductSearchPage />} />
            <Route path="/roles/:roleId" element={<RoleDetailPage />} />
            <Route path="/roles" element={<RoleSearchPage />} />
            <Route path="/users/:userId" element={<UserDetailPage />} />
            <Route path='/users' element={<UserSearchPage/>} />
            <Route path='/timeslots/create' element={<CreateTimeslotPage/>} />
            <Route path='/timeslots/:timeslotId' element={<TimeslotPage/>} />
            <Route path='/timeslots' element={<TimeslotPage/>} />
            <Route path='/oauth/return' element={<OauthReturnPage/>} />
            <Route path='/export/dok' element={<ExportDokPage />} />
            <Route path='/' element={<>Hello cleopatra user.</>} />
        </Routes>
    );
};

export default ApplicationRouter;